import React, { Component, Fragment } from 'react'
// import trashRedPng from '../../common/images/icons/trash-red.png';
import { fetchResturantTax, fetchRestaurantProvince, fetchResturantDeliveryAvailability } from '../../../actions/settings_actions';
import { removeCartItem, removeCartSubItem } from '../../../actions/cart_actions';
// import {S3_URL} from '../../utils/misc';
// import {getParameterByName,getUniqueNameWithTimeStamp,showMsg } from '../../utils/general';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadingSpinner from '../../utils/LoadingSpinner';
import UserCartItemBlock from './components/UserCartItemBlock';
import dropLeftPng from '../../common/images/icons/drop-left.png';
// import ModalExample from './components/ModalExample';
import AuthModal from './components/AuthModal/AuthModal';
import { calculateTotalHelper } from '../../utils/OrdersPages/OrdersHelpers';

// import ModalBody from './components/ModalBody';
class Cart extends Component {
    state = {
        tax: 0,
        taxAmount: 0,
        subTotal: 0,
        total: 0,
        deliveryAvailability: 'loading',
        cartDetail: [],
        selectedDeliveryType: '',
        selectedAuthType: '',
        showAuthModal: false,
        restaurant_province: '',

    }

    // There is only one reason when one needs to pass props to super():

    // When you want to access this.props in constructor.
    // constructor(props) {
    //     super(props)
    // }
    componentDidMount() {
        let cart = this.props.cart;
        // console.log('i am in cart page');
        // console.log('cart');
        // console.log('cart');
        // console.log('cart');
        // console.log('cart');
        // console.log(cart);
        // this.props.fetchResturantTax().then(response => {
        //     // console.log('this.props.objtax in resp');
        //     // console.log(this.props.objtax);
        //     this.setState({ tax: this.props.objtax.tax });
        //     this.calculateTotal(cart);
        //     // console.log(this.props.objtax);
        // });
        this.calculateTotal(cart);
        this.props.fetchRestaurantProvince().then(response => {
            // console.log('this.props.objtax in resp');
            // console.log(this.props.objtax);
            // objRestaurantProvince
            // this.setState({ tax: this.props.objtax.tax });
            // alert(this.props.objRestaurantProvince.restaurant_province)
            let restaurant_province = this.props.objRestaurantProvince.restaurant_province;
            let tax = 13;
            // console.log('restaurant_province')
            // console.log('restaurant_province')
            // console.log(restaurant_province)
            // if(restaurant_province.toLowerCase() === 'ontario'){
            //     // alert('dssdds')
            //     if(parseFloat(this.state.subTotal) <= 4){
            //         tax = 5; 
            //     }else if(parseFloat(this.state.subTotal) > 4){
            //         tax = 13; 
            //     }
            // }
            // alert(this.state.subTotal)
            // alert(tax)
            // alert(typeof this.state.subTotal)
            this.setState({ tax,restaurant_province  },()=>{
                this.calculateTotal(cart);
            });
            // console.log(this.props.objtax);
        });
        this.props.fetchResturantDeliveryAvailability().then(response => {
            // console.log('this.props.objResturantDeliveryAvailability in resp');
            // console.log(this.props.objResturantDeliveryAvailability);
            this.setState({ deliveryAvailability: this.props.objResturantDeliveryAvailability.is_delivery_available });
            // console.log(this.props.objtax);
        });
        // console.log('this.props.objtax');
        // console.log(this.props.objtax);
        window.Cart = this;
    }
    calculateTotal = (cartDetailData) => {
        // let total = 0;
        // cartDetail.forEach((item, index) => {
        //     let isShowIngredientsList = false;
        //     if (item.ingredients && item.ingredients.length > 0) {
        //         isShowIngredientsList = true;
        //         let ingredientTotal = 0;
        //         for (let i = 0; i < item.ingredients.length; i++) {
        //             const element = item.ingredients[i];
        //             if (element.ingredientType === 'extras_charged' && element.is_checked === true) {
        //                 ingredientTotal = parseFloat(parseFloat(ingredientTotal) + parseFloat(element.price)).toFixed(2);
        //             }
        //         }
        //         cartDetail[index].ingredientTotal = ingredientTotal;
        //     } else {
        //         let ingredientTotal = 0;
        //         cartDetail[index].ingredientTotal = parseFloat(ingredientTotal).toFixed(2);
        //     }
        //     if (item.selectedComboItems.length > 0) {
        //         for (let i = 0; i < item.selectedComboItems.length; i++) {
        //             const elementCombo = item.selectedComboItems[i];
        //             let isShowIngredientsListSub = false;
        //             let ingredientTotal = 0;
        //             for (let j = 0; j < elementCombo.ingredients.length; j++) {
        //                 const element = elementCombo.ingredients[j];
        //                 isShowIngredientsListSub = true;
        //                 if (element.ingredientType === 'extras_charged' && element.is_checked === true) {
        //                     ingredientTotal = parseFloat(parseFloat(ingredientTotal) + parseFloat(element.price)).toFixed(2);
        //                 }
        //             }
        //             cartDetail[index].selectedComboItems[i].ingredientTotal = ingredientTotal;
        //             cartDetail[index].selectedComboItems[i].isShowIngredientsList = isShowIngredientsListSub;
        //         }
        //     }
        //     total = parseFloat(parseFloat(total) + parseFloat(item.totalPrice)).toFixed(2);
        //     cartDetail[index].isShowIngredientsList = isShowIngredientsList;
        // });
        let {total,cartDetail} = calculateTotalHelper(cartDetailData);
        this.setState({
            subTotal:total,
            cartDetail
            // showTotal: true
        });
    }
    removeFromCart = (product) => {
        this.props.removeCartItem(product).then(response => {
            this.calculateTotal(this.props.cart);
        });
    }
    removeSubItemFromCart = (product, subProduct) => {
        this.props.removeCartSubItem(product, subProduct).then(response => {
            this.calculateTotal(this.props.cart);
        });
    }
    calculateTax = () => {
        let total = parseFloat(this.state.subTotal).toFixed(2);
        let tax = parseFloat(this.state.tax / 100).toFixed(2);
        let taxAmount = ((total) * (tax)).toFixed(2);
        return taxAmount;
    }
    calculateTotalAmount = () => {
        let total = parseFloat(this.state.subTotal).toFixed(2);
        let tax = parseFloat(this.state.tax / 100).toFixed(2);
        let taxAmount = ((total) * (tax)).toFixed(2);
        return (parseFloat(total) + parseFloat(taxAmount)).toFixed(2);
    }
    handleOnClickBtnDeliveryType = (selectedDeliveryType) => {
        console.log('selectedDeliveryType')
        console.log(selectedDeliveryType)
        let objUser = this.props.user.userData;
        this.setState({ selectedDeliveryType });
        if (objUser.isAuth === false) {
            this.setState({ showAuthModal: true })
        }
        if (selectedDeliveryType === 'pickup') {
            if (objUser.isAuth) {
                this.props.history.push('/address-details/pickup');
            }
        } else if (selectedDeliveryType === 'delivery') {
            if (objUser.isAuth) {
                this.props.history.push('/address-details/delivery');
            }
        }
    }
    handleOnClickBtnAuthType = (selectedAuthType) => {
        console.log('selectedAuthType handleOnClickBtnAuthType')
        console.log(selectedAuthType)
        let objUser = this.props.user.userData;
        this.setState({ selectedAuthType });
        console.log('objUser.isAuth in handleOnClickBtnAuthType')
        console.log(objUser.isAuth)

        if (selectedAuthType === 'login') {
            this.props.history.push(`/login/true/${this.state.selectedDeliveryType}`);
        }else if (selectedAuthType === 'create_account') {
            this.props.history.push(`/address-details/${this.state.selectedDeliveryType}`);
        }else if (selectedAuthType === 'continue_as_guest') {
            this.props.history.push(`/address-details/${this.state.selectedDeliveryType}/guest`);
        }
    }
    handleOnChangeAuthModalToggle = (showAuthModal) => {
        this.setState({ showAuthModal: showAuthModal });
    }

    render() {
        // const objtax = this.props.objtax;
        const objRestaurantProvince = this.props.objRestaurantProvince;
        // const cart = this.props.cart;
        const cart = this.state.cartDetail;
        // console.log('this.props.user.userData')
        // console.log('this.props.user.userData')
        // console.log(this.props.user.userData)
        if (objRestaurantProvince === undefined || (!objRestaurantProvince)) {
        // if (objtax === undefined || (!objtax)) {
            return (
                <LoadingSpinner />
            )
        } else {

            return (
                <Fragment>
                    <header className="container inner-header">
                        <div className="row">
                            <div className="col-12 mb20">
                                <img src={dropLeftPng} alt="icons" className="mw-100 cursor-pointer" onClick={this.props.history.goBack} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>My Cart</h4>
                            </div>
                        </div>
                    </header>
                    <div className="inner-body inner-body-custom myCartPage">
                        <div className="container my-2">
                            <UserCartItemBlock
                                products={cart}
                                type="cart"
                                removeItem={(product) => this.removeFromCart(product)}
                                removeSubItemFromCart={(product, subProduct) => this.removeSubItemFromCart(product, subProduct)}
                            />

                            {
                                cart.length > 0 ?
                                    <Fragment>
                                        <div className="row text-right row-totals mb10">
                                            <div className="col-12">
                                                <p>Sub total : ${this.state.subTotal} </p>
                                            </div>
                                            <div className="col-12">
                                                <p>Tax : ${this.calculateTax()} </p>
                                            </div>
                                            <div className="col-12">
                                                <p>Total Amount : ${this.calculateTotalAmount()} </p>
                                            </div>
                                        </div>
                                        <div className="row product-action-btn">
                                            {/* <div className="col-12">
                                                    <div className="my-cart-btn">
                                                        <ModalExample
                                                            isCustomBtn={true}
                                                            customBtnClasses="btn btn-primary w-100"
                                                            buttonLabel="Complete Order"
                                                            deliveryAvailability={this.state.deliveryAvailability}
                                                            handleOnClickBtnDeliveryType={this.handleOnClickBtnDeliveryType}
                                                        ></ModalExample>
                                                        
                                                    </div>
                                                </div> */}
                                            <div className="col">
                                                <div className="my-cart-btn">
                                                    <button onClick={() => this.handleOnClickBtnDeliveryType('pickup')} className="btn btn-primary w-100" type="button" name="pickup" value="pickup" >
                                                        Pickup
                                                    </button>
                                                </div>
                                            </div>
                                            {this.state.deliveryAvailability === true ?
                                                <div className="col pl5">
                                                    <div className="my-cart-btn">
                                                        <button onClick={() => this.handleOnClickBtnDeliveryType('delivery')} className="btn btn-primary w-100" type="button" name="delivery" value="delivery">
                                                            Delivery
                                                        </button>
                                                    </div>
                                                </div>
                                                : null
                                            }

                                        </div>
                                    </Fragment>
                                    : null
                            }


                        </div>
                    </div>

                    <AuthModal
                        showAuthModal={this.state.showAuthModal}
                        handleOnClickBtnAuthType={this.handleOnClickBtnAuthType}
                        handleOnChangeAuthModalToggle={this.handleOnChangeAuthModalToggle}
                    />
                </Fragment>


            )
        }
    }
}
function mapStateToProps(state) {
    // console.log('state in ingr')
    // console.log(state)
    return {
        cart: state.cart,
        objtax: state.settings.objtax,
        objResturantDeliveryAvailability: state.settings.objResturantDeliveryAvailability,
        objRestaurantProvince: state.settings.objRestaurantProvince,
    };
}

// export default Cart
export default connect(mapStateToProps, { fetchResturantTax, fetchRestaurantProvince, fetchResturantDeliveryAvailability, removeCartItem, removeCartSubItem })(withRouter(Cart));
